import React, { useState } from 'react';
import QRCode from 'qrcode.react';
import './Qr.css'; 

function Qr() {
    const [url, setUrl] = useState('https://regist.provenco.co/'); // Aquí debes definir tu dirección web

    return (
        <div className="BozQr">  
            {url && (
                <div class="caja-Qr">
                    <div class="QRIcons ViewImg-Btn"></div>
                    <p>REGISTRO<br/> PROVENCO 2025</p>
                    <QRCode value={url} />
                </div>
            )}
        </div>
    );
}

export default Qr;
