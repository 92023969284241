import React from 'react';
import './Loading.css'; 

function Loading() {
  return ( 
      <div className='loadding-ppl'>
        <img src="https://provenco.co/images/icono.png" alt="" /> 
      </div> 
  );
}

export default Loading;